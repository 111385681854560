@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.button {
  text-transform: uppercase;
  margin-bottom: 12px;

  @include mx-link;
  font-size: rem(12);

  svg * {
    stroke-width: 4px;
  }
}

.iconButton {
  @include tablet {
    font-size: rem(10);
    min-height: 52px !important;
    padding: 0 !important;
    width: 52px !important;

    svg {
      vertical-align: middle;

      path {
        stroke-width: 1.5;
      }
    }
  }
}
