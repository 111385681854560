@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.overlay {
  z-index: 101;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
}

.modal {
  position: absolute;
  background: #fff;
  border: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: none;
  width: 100%;
  height: 100%;

  @include small-screen() {
    display: flex;
    align-items: center;
  }
}

.modalImage {
  max-height: 100vh;
  display: block;
  margin: auto;

  &.zoomedIn {
    cursor: -webkit-grab;
    cursor: grab;
  }

  &.zoomedOut {
    cursor: zoom-in;
  }

  @include small-screen() {
    max-width: 100vw;
  }
}

.closeIcon {
  display: block;
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 102;

  svg {
    height: 75px;
    width: 75px;

    path {
      stroke: $black;
      stroke-width: 1.2px;
    }
  }

  &:hover path {
    stroke: $neutral;
  }

  @include small-screen() {
    right: 12px;
    top: 12px;

    svg {
      height: 42px;
      width: 42px;
    }
  }
}

:global(.ReactModal__Overlay) {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

:global(.ReactModal__Overlay--after-open) {
  opacity: 1;
}

:global(.ReactModal__Overlay--before-close) {
  opacity: 0;
}
