@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.price {
  position: relative;
  display: inline-block;
}

.discount {
  display: none;
}

.setDiscount {
  display: inline-block;
  @include mx-font-title;
  font-size: rem(11);
  text-transform: none;
  letter-spacing: normal;
  color: $accent-colour;
  margin-right: 7px;

  &:before {
    content: '%';
    @include mx-font-title;
    letter-spacing: normal;
    color: $mx-color-white;
    display: inline-block;
    margin-right: 5px;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5em;
    text-align: center;
    border-radius: 100%;
    background: $accent-colour;
  }

  @include max-screen(767px) {
    order: 2;
    width: 100%;
    margin-right: 0;
  }
}

.mainPrice {
  white-space: nowrap;

  @include max-screen(767px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .priceText {
      display: block;
      width: 100%;
      text-align: right;
      @include body-font-3;
    }
  }
}

.oldPriceContainer {
  display: flex;
}

.oldPrice {
  @include body-font;
  white-space: nowrap;
  color: $neutral;
  text-decoration: line-through;
  text-decoration-color: rgba(142, 142, 143, 0.8);
  display: inline-block;
  position: absolute;
  right: 0;
  top: -1.5em;
}

.small {
  @include body-font-2;
  line-height: inherit;
}

.medium {
  @include subheader-font-demi;
}

.big {
  @include h3-font-demi;

  @include max-screen(992px) {
    @include subheader-font-demi;
  }

  @include max-screen(320px) {
    @include body-font-3;
    color: $charcoal;
  }
}

.inline {
  .oldPrice {
    position: static;
    margin-right: 0px;
    display: block;
    margin-bottom: -7px;
  }

  &.big {
    .oldPrice {
      margin-bottom: -4px;
    }
  }

  @include max-screen(1024px) {
    .discount {
      display: inline-block;
      font-size: rem(8);
      margin-right: 0.8em;
      vertical-align: middle;
      margin-top: -0.45rem;

      span {
        font-size: rem(15) !important;
      }
    }
  }

  @include max-screen(767px) {
    .discount {
      display: none;
    }
  }
}

.block {
  .oldPrice {
    display: block;
    position: static;
    @include body-font-2;
  }
}

.discountAfter {
  display: flex;

  .oldPriceContainer {
    order: 1;
  }
  .oldPrice {
    margin-left: 0.6em;
    order: 1;
    @include body-font-2;
  }

  .mainPrice {
    margin: 0;
    order: 0;
  }

  .tax {
    order: 2;
    color: $neutral;
  }
}

.discountBefore {
  display: flex;
  align-items: baseline;

  .mainPrice {
    margin: 0 0 0 12px;
  }

  @include max-screen(767px) {
    .oldPrice {
      @include body-font;
    }
  }
}

.column {
  @include max-screen(767px) {
    flex-flow: column;
    align-items: normal;
  }
}

.simpleDiscount {
  text-align: right;
  display: flex;
  flex-direction: column;

  .priceText {
    vertical-align: text-bottom;
  }

  .oldPrice {
    position: unset;
    margin-bottom: -10px;
  }

  .discount {
    display: none;
  }

  .setSimpleDiscount {
    display: inline-block;
    margin-right: 2px;
    vertical-align: text-bottom;
    margin-bottom: 3px;

    &:before {
      content: '%';
      @include mx-font-title;
      letter-spacing: normal;
      color: $mx-color-white;
      display: inline-block;
      margin-right: 5px;
      width: 1.5em;
      height: 1.5em;
      line-height: 1.5em;
      text-align: center;
      border-radius: 100%;
      background: $neutral;
    }
  }

  @include max-screen(992px) {
    vertical-align: bottom;

    .setSimpleDiscount {
      margin-right: 0;
    }
  }

  @include max-screen(767px) {
    .priceText {
      width: auto;
    }

    .mainPrice {
      flex-wrap: nowrap;
    }

    .oldPrice {
      @include body-font-2;
      margin-bottom: -8px;
    }
  }

  @include max-screen(320px) {
    .oldPrice {
      margin-bottom: -6px;
    }
  }
}

.campaignTooltipMsg {
  max-width: 335px;
  font-size: 14px;

  & > p {
    padding: 35px 35px 15px 20px;
    text-align: left;
  }
}

.red {
  color: $red_3016;
}

// Subcategory new layout experiment https://mycshq.atlassian.net/browse/MYCS-10485
.subcatRedHighlight {
  @include body-font-2;
  top: 25px;
  &.withoutOldPrice {
    top: 0;
  }
  .oldPrice {
    @include body-font-2;
    top: -25px;
  }

  .mainPrice {
    color: $mx-color-red;
  }

  .discount {
    display: none;
  }

  @include max-screen(1024px) {
    @include body-font-4;
    top: 23px;

    .oldPrice {
      @include body-font-4;
      display: inline-block;
    }
  }

  @include max-screen(767px) {
    @include body-font-2;
    top: 0;
    align-items: center;

    .oldPrice {
      @include body-font-2;
      top: 0;
      margin-bottom: 0;
    }
  }
}

.tax {
  color: $ash;
  margin-left: 11px;
  align-self: center;
  display: inline-block;
  vertical-align: middle;
  @include body-font-6;

  &.taxNoMarginLeft {
    margin-left: 0;
  }
}

.configuratorLayout {
  justify-content: right;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  &.inline {
    flex-direction: row;

    .mainPrice {
      margin: 0 0 0 12px;
    }
  }

  .tax {
    flex-basis: 100%;
  }

  @include max-screen(767px) {
  }

  .oldPrice {
    position: relative;
    top: 0;
  }

  .mainPrice {
    margin: 0;
  }

  @include max-screen(992px) {
    .oldPriceContainer {
      margin-bottom: -10px;
    }

    .oldPrice {
      @include body-font-2;
    }
  }
}

.iconHoverContainer {
  cursor: pointer;
  opacity: 0.85;
  &:hover {
    opacity: 1;
  }
}

.pdpPrice {
  .mainPrice .priceText {
    @include max-screen(767px) {
      @include subheader-font-demi;
    }
  }
}
