@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  height: 682px;
  position: relative;
  @include max-screen(1280px) {
    height: 53vw;
  }

  @include max-screen(768px) {
    height: auto;
  }
}

.videoContainer {
  height: 100%;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.videoWrapper {
  display: inline-block;
  height: 100%;
  position: relative;
}

.video {
  display: block;
  height: 100%;
  margin: auto;
  max-width: 100%;

  &:hover {
    cursor: pointer;
  }

  @include max-screen(768px) {
    height: auto;
  }
}

.overlay {
  background: rgba(0, 0, 0, 0.1);
  bottom: 0;
  display: none;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.overlayImage {
  @include fadeIn(200ms);
  display: none;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: auto;
  margin: auto;

  @include max-screen(767px) {
    left: -25%;
  }
}

.content {
  color: $white;
  display: none;
  padding: 0 104px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 43%;
  flex-direction: column;
  justify-content: center;

  @include max-screen(1150px) {
    padding: 30px;
  }

  @include min-screen(768px) {
    display: none;
  }

  @include max-screen(767px) {
    color: $charcoal;
    display: block;
    padding: 15px 0 5px;
    position: relative;
    width: 100%;
  }
}

.title {
  @include subheader-font-demi;
  color: $white;
  margin-bottom: 15px;

  @include max-screen(1150px) {
    @include body-font-3;
  }

  @include max-screen(767px) {
    @include subheader-font-demi;
  }
}

.text {
  @include body-font-2;

  @include screen(768px, 1150px) {
    @include body-font-4;
  }
}

.button {
  display: block;
  margin: 25px 0;

  button {
    @include max-screen(767px) {
      width: 100%;
    }
  }
}

.playIcon {
  bottom: 0;
  display: none;
  left: 30%;
  margin: auto !important;
  position: absolute;
  right: 0;
  top: 0;
  height: 125px;
  width: 125px;
  z-index: 1;

  &:hover {
    cursor: pointer;
  }

  svg {
    display: block;
    height: 125px;
    width: 125px;
  }

  @include max-screen(1150px) {
    left: 43%;
    height: 97px;
    width: 97px;

    svg {
      display: block;
      height: 97px;
      width: 97px;
    }
  }

  @include max-screen(767px) {
    left: 0;
    height: 80px;
    width: 80px;

    svg {
      height: 80px;
      width: 80px;
    }
  }
}

.closeIcon {
  height: 32px;
  position: absolute;
  right: 8px;
  top: 8px;
  width: 32px;
  z-index: 2;

  &:hover {
    cursor: pointer;
  }

  svg {
    height: 32px;
    width: 32px;

    * {
      stroke: $white;
      stroke-width: 3;
    }
  }

  @include max-screen(768px) {
    right: -7px;
    top: -45px;

    svg * {
      stroke: $black;
    }
  }

  @include max-screen(767px) {
    display: none;
  }
}

.initial {
  .overlayImage,
  .playIcon {
    display: block;
  }

  .content {
    display: flex;
  }

  .closeIcon {
    display: none;
  }

  .videoContainer {
    overflow: hidden;
  }
}

.paused {
  .overlay {
    display: block;
  }

  .playIcon {
    @include fadeIn(100ms);
    display: block;
    left: 0;
  }
}

.playing {
  .overlayImage {
    display: none;
  }
}

.whiteLayout {
  .closeIcon {
    @include max-screen(768px) {
      height: 40px;
      width: 40px;
      top: 10px;
      right: 10px;

      svg {
        height: 40px;
        width: 40px;

        * {
          stroke: $white;
        }
      }
    }
  }
}

.centeredLayout {
  .playIcon {
    left: 0;

    // Hide play icon on mobile, because it interferes with the default play icon on Android
    @include max-screen(767px) {
      display: none;
    }
  }

  .closeIcon {
    top: -30px;
    right: -7px;
    height: 25px;
    width: 25px;

    svg {
      height: 25px;
      width: 25px;

      * {
        stroke-width: 3;
        stroke: $black;
      }
    }
  }
}

.darkLayout {
  .closeIcon {
    display: block;
    height: 32px;
    width: 32px;
    right: 5px;
    top: 5px;
    z-index: 1;

    svg {
      height: 32px;
      width: 32px;

      * {
        stroke: $white;
      }
    }
  }
}

.videoNoText {
  .videoWrapper {
    width: 100%;
  }
  .playIcon {
    left: 0%;
  }
}

.textContainer {
  text-align: center;
  margin-top: 12px;
  margin-bottom: 10px;
  @include max-screen(991px) {
    margin-bottom: 30px;
  }
  @include max-screen(768px) {
    margin-top: 0px;
    text-align: left;
    padding: 0 10px;
  }
  .title {
    color: $pale-gold;
    @include body-font-3;

    @include max-screen(768px) {
      text-align: left;
    }
  }
  p {
    color: $ash;
    @include body-font-2;
  }

  h1,
  h2,
  h3 {
    margin-bottom: 0px;
  }
}
