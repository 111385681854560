@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.imageCredit {
  @include body-font-6;
  text-align: center;
  background-color: $light-peach;
  color: $charcoal;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px;
}
