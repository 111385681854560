@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.centerAlignment {
  :global(.slick-arrow),
  :global(.slick-arrow:before) {
    @include min-screen(768px) {
      height: 60px;
      width: 50px;
    }
  }

  :global(.slick-dots) {
    @include min-screen(768px) {
      display: block !important;
      position: absolute;
      bottom: 7px;

      button {
        background-color: $white !important;
      }
    }

    @include max-screen(767px) {
      border: none;
      padding: 30px 0 0;
    }
  }

  :global(.slick-active) {
    @include min-screen(768px) {
      button {
        background-color: $accent-colour !important;
      }
    }
  }

  :global(.slick-slide) {
    max-height: 500px;
    overflow: hidden;
    padding: 0 6vw;

    img {
      width: 100%;
    }

    @include max-screen(768px) {
      max-height: 330px;
    }

    @include max-screen(767px) {
      padding: 0;
    }
  }
}

.linkContainer {
  display: block;
  position: relative;

  &.hasImageCredit {
    width: fit-content;
    margin: auto;
  }
}

.videoContainer {
  padding-top: 100%;
  height: 0px;
  position: relative;
  .video {
    margin: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    :global(.Video__videoWrapper) {
      position: initial;
      height: 100%;
    }
    :global(.Video__video) {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    img {
      max-width: fit-content;
    }
  }
}
