@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.label {
  @include body-font-4;
  background-color: $light-pink;
  padding: 5px 10px;
  display: inline-block;
  vertical-align: middle;
  height: 30px;

  span {
    color: $charcoal;
  }

  svg {
    * {
      stroke: $charcoal;
    }
  }

  .iconContainer {
    height: 15px;
    margin-right: 8px;
  }
}
