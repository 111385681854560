@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.bigTileContainer {
  position: relative;

  .expandingButton {
    max-width: 44px;
    overflow: hidden;
    transition: max-width 200ms ease-in;
  }
  @include min-screen(768px) {
    &:hover {
      .expandingButton {
        max-width: 200px;

        span {
          display: inline-block;
        }
      }
    }
  }

  .titleText {
    @include maisonneue-book;
    font-size: rem(14);
    line-height: rem(25);
    letter-spacing: rem(0.56);
    color: $charcoal;
    flex-shrink: 1;

    @include max-screen(1024px) {
      font-size: rem(12);
      line-height: rem(28);
      letter-spacing: rem(0.48);
    }
  }

  .subtitle {
    @include maisonneue-book;
    font-size: rem(12);
    line-height: rem(28);
    letter-spacing: rem(0.48);
    color: $ash;
    margin: 0 !important;
    padding-right: 70px;

    @include max-screen(992px) {
      font-size: rem(10);
      line-height: rem(19);
      letter-spacing: rem(0.4);
    }
  }

  .price {
    position: relative;
    top: 0;
    right: 0;
    flex-shrink: 0;
    margin-left: 15px;
  }

  .customizeIconCta {
    width: auto !important;
    height: 42px;
    position: absolute;
    top: 10px;
    right: 10px;
    white-space: nowrap;

    span {
      display: none;
      transition: display 3s linear;
    }
  }

  .labelContainer {
    position: absolute;
    top: 25px;
    left: 25px;
  }
}

.splitButtonGroup {
  position: absolute;
  bottom: 20px;
  width: 100%;

  @include max-screen(767px) {
    display: none;
  }

  :global(.ButtonGroup__iconButton) {
    border-left: none;
  }
}

.mobileLayout {
  .titleText {
    font-size: rem(14);
    line-height: rem(25);
    letter-spacing: rem(0.56);
  }

  .price {
    margin-left: 0;

    > span {
      top: 0;
    }
  }

  .customizeIconCta {
    top: 5px;
    right: 5px;
  }

  .labelContainer {
    position: relative;
    top: 0;
    left: 0;
    margin-top: 5px;
  }
}
