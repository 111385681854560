@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.slider {
  padding: 0 40px;
  position: relative;

  &.fullWidth {
    padding: 0;

    :global(.slick-list) {
      padding: 0 !important;
    }
  }

  &.centerMode {
    :global(.slick-list) {
      padding: 0;
    }
  }

  &.arrowsUp {
    :global(.slick-arrow) {
      top: 50%;
    }
  }

  @include max-screen(767px) {
    padding: 0 10px;
  }
}

:global {
  .slick-slider {
    @include min-screen(768px) {
      position: initial !important  ;
    }
    box-sizing: border-box;
    display: block;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    display: block;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
  }

  .slick-list:focus {
    outline: none;
  }

  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
  }

  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
  }

  .slick-slide > div,
  .slick-slide > div > div {
    display: block !important;
  }

  .slick-slide img {
    display: block;
    max-width: 100%;
  }

  .slick-slide.dragging img {
    pointer-events: none;
  }

  .slick-initialized .slick-slide {
    display: block;
  }

  .slick-loading .slick-slide {
    visibility: hidden;
  }

  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }

  .slick-arrow.slick-hidden {
    display: none;
  }

  .slick-arrow {
    background: transparent;
    border: none;
    color: transparent;
    cursor: pointer;
    outline: 0;
    padding: 0;
    position: absolute;
    height: 45px;
    width: 40px;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1;

    @include max-screen(767px) {
      top: 100%;
      transform: translate(0, -105%);
    }
  }

  .slick-arrow:before {
    background: url('/img/icons/general/arrow-chevron-left.svg') no-repeat 50%;
    background-size: contain;
    content: '';
    display: block;
    height: 45px;
    width: 40px;
  }

  .slick-prev {
    left: 0;
  }

  .slick-next {
    right: 0;
  }

  .slick-next:before {
    transform: rotate(180deg);
  }

  .slick-arrow.slick-disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  .slick-dots {
    display: none !important;
    list-style: none;
    margin: 0;
    padding: 20px 0 0;
    text-align: center;
    width: 100%;

    @include max-screen(767px) {
      border: 1px solid $ice;
      display: block !important;
    }
  }

  .slick-dots li {
    position: relative;
    display: inline-block;
    cursor: pointer;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    padding: 0;
  }

  .slick-dots li button {
    background-color: $mx-color-grey-5;
    border: 0;
    border-radius: 50%;
    color: transparent;
    cursor: pointer;
    display: block;
    height: 10px;
    outline: 0;
    padding: 0;
    width: 10px;
  }

  .slick-dots li.slick-active button {
    background-color: $mx-color-grey-3;
  }

  .slick-active {
    z-index: 1;
  }
}

.previews {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 5px;

  @include max-screen(767px) {
    padding: 5px 0;
    border-top: 1px solid rgba(186, 186, 186, 0.5);
    border-bottom: 1px solid rgba(186, 186, 186, 0.5);
  }
}

.preview {
  margin: 0 5px;
  height: 50px;
  border: 1px solid transparent;

  &.active {
    border-color: $ash;
  }

  @include max-screen(767px) {
    width: 55px;
    height: 55px;
    margin: 5px;
  }
}

.previewContainer {
  position: relative;
  &:nth-child(1) {
    margin-left: 5px;
  }
  .overlay {
    background-color: rgba(0, 0, 0, 0.15);
    z-index: 1;
    width: 55px;
    height: 55px;
    margin: 5px;
    top: 0;
    pointer-events: none;
    position: absolute;
  }
  .overlayActive {
    background-color: transparent;
  }
}

// Fix for empty space after last slide (Designs Carousel)
.scrollRight {
  :global {
    .slick-track {
      transform: translate3d(calc(-100% + 100vw - 10px), 0, 0) !important;
    }
  }
}

.playIconContainer {
  .videoPreview {
    border: 1px solid transparent;
    &.active {
      border-color: $ash;
    }
  }

  user-select: none;
  outline: none;
  width: 55px;
  height: 55px;
  position: relative;
  background: $ice;
  margin: 5px;

  img {
    width: 55px;
    height: 55px;
    object-fit: cover;
  }
}

.playIcon {
  display: block;
  box-sizing: border-box;
  height: 55px;
  width: 55px;
  padding: 14px 19px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;

  svg {
    width: 22px;
    height: 26px;
  }
}
