@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  display: inline-block;
  overflow: hidden;
  padding: 15px;
  width: 100%;
  position: relative;

  @include max-screen(768px) {
    padding: 10px;
  }
  @include max-screen(767px) {
    padding: 5px;
  }
}

.imageBlock {
  display: block;
  position: relative;

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  .imageLink {
    display: block;
  }

  [class^='Loader__dots'] {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }
}

.text {
  padding: 15px 7px;
  padding-bottom: 0;

  @include max-screen(767px) {
    padding: 15px 10px;
  }

  .title {
    display: block;
    margin: 13px 0 0;
  }

  .subtitle {
    display: block;
    margin: 6px 0;
  }
}

.bigTile {
  .imageBlock {
    height: auto;
    padding-top: 0;

    img {
      position: relative;
      width: 100%;
      display: block;
      height: auto;
    }
  }

  .text {
    padding: 0;
    margin-top: 18px;

    @include max-screen(1024px) {
      margin-top: 14px;
    }
  }

  .title {
    margin: 0;
    line-height: rem(25);

    @include max-screen(1024px) {
      line-height: rem(28);
    }

    @include min-screen(768px) {
      a {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
      }
    }
  }

  &.gridBanner {
    .imageBlock {
      display: block;
      position: relative;
      height: auto;
      padding-top: 0;

      img {
        position: relative;
        height: 100%;
        width: 100%;
      }
    }
  }

  &.mobileLayout {
    .text {
      position: relative;
      margin-top: 10px;
    }

    .title {
      a {
        display: block;
      }
      line-height: rem(25);
    }
  }
}

.imagesCarousel {
  &:hover {
    :global {
      .slick-arrow,
      .slick-dots {
        opacity: 1;
      }
    }
  }

  :global {
    .slick-arrow,
    .slick-dots {
      opacity: 0;
      transition: opacity 200ms ease-in-out;
      z-index: 3;

      @include max-screen(1024px) {
        opacity: 1;
      }
    }

    .slick-arrow {
      height: 100%;
      width: 30px;

      &:before {
        height: 30px;
        width: 30px;
      }

      @include max-screen(1024px) {
        display: none !important;
      }
    }

    .slick-dots {
      display: block !important;
      position: absolute;
      bottom: -2px;

      button {
        background-color: $white;
        border: solid 0.8px $grey-sky;
      }

      .slick-active {
        button {
          background-color: $gold;
        }
      }
    }
  }

  &.imageBlock {
    &.gridBannerCarousel {
      padding-top: 48%;
      height: 0;

      @include max-screen(1024px) {
        padding-top: 41%;
      }

      @include max-screen(992px) {
        padding-top: 46%;
      }

      :global {
        .Carousel__slider {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          overflow: hidden;
        }
      }

      img {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        max-height: 400px;
        object-fit: cover;
      }
    }
  }
}

.removeIcon {
  position: absolute;
  top: 17px;
  right: 16px;

  svg {
    height: 39px;
    width: 39px;

    path {
      stroke-width: 3;
    }

    * {
      stroke: $charcoal;
    }
  }

  @include max-screen(767px) {
    top: 3px;
    right: 2px;

    svg {
      height: 30px;
      width: 30px;
    }
  }
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: $white;
  opacity: 0.5;
  top: 0;
  z-index: 2;
}

.removeMessageContainer {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 0 20px 19px;
  z-index: 3;

  .block {
    background-color: $white;
    text-align: center;
    padding: 20px 10px;

    .removeMessageText {
      @include maisonneue-book;
      font-size: rem(14);
      line-height: rem(25);
      letter-spacing: rem(0.56);
      color: $charcoal;
      margin-bottom: 10px;
    }
  }

  @include max-screen(1024px) {
    padding: 0 10px 10px;
  }

  @include max-screen(767px) {
    padding: 10px 0 0;
    top: 0;
    position: relative;

    .block {
      text-align: left;
      padding: 0;

      .removeMessageText {
        margin-bottom: 5px;
        line-height: rem(20);
      }
    }

    .undoRemoveButton {
      text-align: left;

      span {
        font-size: rem(12);
        line-height: rem(20);
        letter-spacing: rem(1.71);
      }
    }
  }
}
