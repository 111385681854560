@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

$caretLength: 18px;
$caretWidth: $caretLength * 0.5;

.popover {
  pointer-events: initial !important;
  transition-property: opacity !important;
  z-index: 99;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);

  :global(.tooltip-inner) {
    background-color: $charcoal !important;
    border-radius: 0;
    color: $ice;
  }

  &[data-show] {
    .popoverArrow {
      &::before {
        position: absolute;
        width: 8px;
        height: 8px;
        z-index: -1;
      }

      &::before {
        content: '';
        transform: rotate(45deg);
        background: $mx-color-white;
      }
    }

    &:global(.hint) {
      a,
      a:hover {
        text-decoration: underline;
        color: inherit;
      }

      a:hover {
        opacity: 0.75;
      }

      .popoverArrow {
        &::before {
          background-color: $charcoal !important;
          background: #000;
        }
      }
    }
  }

  &[data-popper-placement^='top'] > .popoverArrow {
    bottom: 4px;
  }

  &[data-popper-placement^='bottom'] > .popoverArrow {
    top: -4px;
  }

  &[data-popper-placement^='left'] > .popoverArrow {
    right: 4px;
  }

  &[data-popper-placement^='right'] > .popoverArrow {
    left: -4px;
  }
}

.content {
  background: $mx-color-white;
  position: relative;
  height: 100%;
  z-index: 2;
}

.closeIcon {
  cursor: pointer;
  display: inline-block;
  height: 30px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 30px;
  height: 30px;
  z-index: 10;

  svg {
    height: 30px;
    width: 30px;
  }
}

.toggle {
  display: inline-block;
}

.mobileOverlay {
  @include max-screen(767px) {
    left: 0 !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    position: fixed !important;
    height: 100%;
    margin: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: none !important;
    background-color: rgba(0, 0, 0, 0.5);

    .content {
      background: transparent;
    }

    :global(.Popover-body) {
      height: 100%;
      border: none;
    }

    :global(.Popover-tip) {
      display: none;
    }
  }
}
