@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

@include screen(768px, 1023px) {
  .allowVerticalScroll {
    // to overwrite inline style from Hammer.js component
    touch-action: pan-y !important;
  }
}
