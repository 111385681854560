@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  background: $mx-color-primary;
  border-radius: 50%;
  width: 3.3em;
  height: 3.3em;
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    width: 100%;
    text-align: center;
    color: $white;
    @include maisonneue-demi;
  }
}
