@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.dimensions {
  @include mx-font-product-caption;
  line-height: 1.3;
}

.dimensionsInline div {
  display: inline;

  @include max-screen(767px) {
    margin: 5px;
  }
  
  @include max-screen(500px) {
    display: flex;
  }

}

.compact div {
  font-size: rem(11);
  @include max-screen(767px) {
    margin: 0;
  }
}
