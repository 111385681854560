@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  display: block;

  :global(.slick-slide) {
    @include max-screen(767px) {
      padding: 0 5px;
    }
  }

  :global(.slick-arrow) {
    top: 35%;
    transform: none;
    background-color: $light-peach;
    height: 59px;
    width: 59px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

    &:before {
      background-image: url('/img/icons/general/arrow-chevron-left.svg');
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    @include min-screen(993px) {
      &:hover {
        background-color: $ash;
        &:before {
          background-image: url('/img/icons/general/arrow-chevron-left-white.svg');
        }
      }
    }

    @include max-screen(992px) {
      height: 45px;
      width: 45px;
      top: 30%;

      &:before {
        background-size: 80%;
      }

      &:active {
        background-color: $ash;
        &:before {
          background-image: url('/img/icons/general/arrow-chevron-left-white.svg');
        }
      }
    }
  }

  :global(.slick-disabled) {
    display: none !important;
  }

  :global(.slick-dots) {
    display: none !important;
  }

  :global(.slick-list) {
    @include max-screen(767px) {
      padding: 0 5px !important;
    }
  }

  :global(.Carousel__slider) {
    padding: 0 !important;
  }
}

.slide {
  display: block;
}

@include max-screen(991px) {
  .preview {
    button {
      padding: 11px 28px;

      span {
        font-size: rem(12);
      }
    }
  }
}
