@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.videoModalOverlay {
  position: relative;

  &:after {
    background: $mx-color-white-transparent-3;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    min-height: 100%;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 12;
  }

  &.darkOverlay:after {
    background: rgba(55, 55, 55, 0.9);
  }
}

.videoModal {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 13;

  &:focus {
    outline: none;
  }

  @include max-screen(768px) {
    align-items: flex-start;
    top: 13.5%;
  }
}

.videoOverlay {
  background: rgba(0, 0, 0, 0.1);
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.video {
  height: auto;
  margin: auto !important;

  video {
    display: block;
    height: auto;
    z-index: 14;

    @include min-screen(768px) {
      max-height: 60.5vh;
      max-width: 84vw;
    }
  }
}

.darkLayout {
  &.videoModal {
    @include max-screen(767px) {
      align-items: center;
      top: 0;
    }
  }

  .videoContainer video {
    @include max-screen(767px) {
      max-width: 80vw;
      max-width: 100vw;
    }
  }
}
